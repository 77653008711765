/* eslint-disable no-underscore-dangle */
import { create } from 'apisauce';
import Cookie from 'universal-cookie';
import HTTPError from './errors/http-error';
// eslint-disable-next-line import/no-cycle
import { iOSSafari } from './utils';
// eslint-disable-next-line import/no-cycle
import healthCheckService from '../services/healthCheck/healthCheck-service';

const cookies = new Cookie();

/**
 *
 * api function()
 * wrapper around the api sauce create object
 * need to check on each call whether the auth cookie is set,
 * if it is, we want to send the token up with each request
 * if it isn't, our AuthContext context will bounce the user
 * out of the app.
 *
 * method: get, post, put, delete
 * endpoint: the api we are looking to hit eg. "/auth/verify"
 * data: object containing our request body data
 *
 * @param {string} method
 * @param {string} endpoint
 * @param {obj} data
 */

const api = async (
  method,
  endpoint,
  data,
  throwOnError,
  redirectOnAuthIssue = true,
  skipAuthToken = false,
  config = {},
) => {
  let apiInit;

  if (skipAuthToken) {
    const cookie = cookies.get('vidatec-template-token') || '';

    let headers = {};

    if (cookie) headers = { Authorization: `Bearer ${cookie}` };

    apiInit = create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      withCredentials: true,
      headers,
    });
  } else {
    apiInit = create({
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
    });
  }

  // Add interceptors on respose to handle refresh token
  apiInit.axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // Intercept passport error response and trigger the token exchange
      if (error.response
          && error.response.status === 401
          && error.response.data === 'Unauthorized'
          && !originalRequest._retry) {
        originalRequest._retry = true;
        // Get refresh token
        const refreshToken = cookies.get('vidatec-template-refresh-token') || '';

        try {
          // Call the token exchange API
          const resp = await apiInit.post(`${process.env.REACT_APP_API_URL}/api/auth/token-exchange`, { refresh_token: refreshToken });

          // store the new access token and refresh token
          if (resp.ok) {
            await cookies.set('vidatec-template-token', resp.data.accessToken, { path: '/' });
            await cookies.set('vidatec-template-refresh-token', resp.data.refreshToken, { path: '/' });

            originalRequest.headers.Authorization = `Bearer ${resp.data.accessToken}`;
          } else {
            window.location = '/logout';
          }

          // Pocceed with the original request now that we have a valid access token
          return apiInit.axiosInstance(originalRequest);
        } catch (err) {
          window.location = '/logout';
        }
      }

      return Promise.reject(error);
    },
  );

  try {
    const result = await apiInit[method](endpoint, data, config);
    if (redirectOnAuthIssue && (result.status === 401 || result.status === 403)) {
      window.location = '/logout';
    }

    // If not status code it means that the server is down
    // FreshDesk 7098 - Exclude Safari browsers from this check due to some users
    // reporting infinite loop between error page and home page
    if (!iOSSafari() && result && result.status === null) {
      // eslint-disable-next-line no-console
      console.info('Checking server status due to the request:', result);
      const isServerDown = await healthCheckService.isServerDown();
      if (isServerDown && window.location.pathname !== '/error') {
        window.location = '/error';
      }
    }

    // If we prefer throwing errors, rather than depending on checking
    // response status, then throw.
    if (throwOnError && !result.ok) {
      throw new HTTPError(result.status, result.data);
    }

    return result;
  } catch (err) {
    // No op
  }
};

export default api;
