/* eslint-disable import/no-cycle */
import api from '../../lib/http';

export default {
  getAllOrganisations: async (
    userId,
  ) => {
    const response = await api(
      'get',
      `organisation/user-in-organisation/${userId}`,
      {},
      false,
      false,
      true,
    );

    return response;
  },

  getOrganisation: async (
    organisationId,
  ) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}`,
      null,
      false,
      false,
      true,
    );

    return result.data;
  },

  updateOrganisation: async (
    organisationId,
    organisationUpdate,
  ) => {
    const result = await api(
      'patch',
      `/organisation/${organisationId}`,
      organisationUpdate,
      false,
      false,
      true,
    );

    return result;
  },

  getUsersInOrganisation: async (
    organisationId,
  ) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}/users`,
      null,
      false,
      false,
      true,
    );

    return result;
  },

  inviteUsersToOrganisation: async (
    organisationId,
    newUsers,
  ) => {
    const result = await api(
      'post',
      `/organisation/${organisationId}/user/add`,
      newUsers,
      false,
      false,
      true,
    );

    return result;
  },

  removeUserFromOrganisation: async (
    organisationId,
    userId,
  ) => {
    const result = await api(
      'delete',
      `/organisation/${organisationId}/user/${userId}`,
      null,
      false,
      false,
      true,
    );

    return result;
  },

  createOrganisation: async (
    userId,
    newFacility,
  ) => {
    const result = await api(
      'post',
      `/organisation/add/${userId}`,
      newFacility,
      false,
      false,
      true,
    );

    return result;
  },

  getOrganisationDetails: async (
    organisationId,
  ) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}/details`,
      null,
      false,
      false,
      true,
    );

    return result;
  },

  updateOrganisationCourses: async (
    organisationId,
    newOrgDetails,
  ) => {
    const result = await api(
      'patch',
      `/organisation/${organisationId}/details`,
      newOrgDetails,
      false,
      false,
      true,
    );

    return result;
  },

  getPartnerLogoIds: async (orgId, countryId) => {
    const result = await api(
      'get',
      `/organisation/${orgId}/partner-logos/country/${countryId}`,
      {},
      false,
      false,
      true,
    );

    return result.data;
  },
  getOncourseLogoId: async (orgId, countryId) => {
    const result = await api(
      'get',
      `/organisation/${orgId}/oncourse-logos/country/${countryId}`,
      {},
      false,
      false,
      true,
    );

    return result.data;
  },

  getFooterLogoIds: async (orgId, countryId) => {
    const result = await api(
      'get',
      `/organisation/${orgId}/footer-logos/country/${countryId}`,
      {},
      false,
      false,
      true,
    );

    return result.data;
  },

  getDocuments: async (organisationId) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}/documents`,
      null,
      false,
      false,
      true,
    );

    return result;
  },

  getDocumentData: async (organisationId, documentId) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}/documents/${documentId}/data`,
      null,
      false,
      false,
      true,
    );

    return result.data;
  },

  getDocument: async (organisationId, documentId) => {
    const result = await api(
      'get',
      `/organisation/${organisationId}/documents/${documentId}`,
      null,
      false,
      false,
      true,
      { responseType: 'arraybuffer' },
    );

    return result;
  },

  uploadDocument: async (
    organisationId,
    data,
    config,
  ) => {
    const result = await api(
      'post',
      `/organisation/${organisationId}/documents`,
      data,
      false,
      false,
      true,
      config,
    );

    return result;
  },
  updateDocument: async (
    organisationId,
    documentId,
    newDocumentDetails,
  ) => {
    const result = await api(
      'patch',
      `/organisation/${organisationId}/document/${documentId}`,
      newDocumentDetails,
      false,
      false,
      true,
    );

    return result;
  },

  deleteDocument: async (organisationId, documentId) => {
    const result = await api(
      'delete',
      `/organisation/${organisationId}/documents/${documentId}`,
      null,
      false,
      false,
      true,
    );

    return result;
  },

  getOrganisationGroups: async () => {
    const result = await api(
      'get',
      '/organisation/organisation-groups',
      {},
      false,
      false,
      true,
    );

    return result.data;
  },
  reInviteUser: async (orgId, userId, message) => {
    const result = await api(
      'post',
      `/organisation/${orgId}/user/${userId}/re-invite`,
      { message },
      false,
      true,
      true,
    );

    return result;
  },
  checkInvitedStatus: async (orgId, userId) => {
    const result = await api(
      'get',
      `/organisation/${orgId}/user/${userId}/update-invited-status`,
      {},
      false,
      true,
      true,
    );

    return result;
  },
  switchOwner: async (orgId, userId, newUserId) => {
    const result = await api(
      'post',
      `/organisation/${orgId}/user/${userId}/switch-owner`,
      { userId: newUserId },
      false,
      true,
      true,
    );

    return result;
  },

};
