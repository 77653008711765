/* eslint-disable import/no-cycle */
import AuthenticationError from '../../lib/errors/authentication-error';
import PermissionsError from '../../lib/errors/permissions-error';
import HTTPError from '../../lib/errors/http-error';
import api from '../../lib/http';

export default {
  login: async (email, password, isTournamentLogin) => {
    const response = await api(
      'post',
      'auth/login',
      { email_address: email, password, isTournamentLogin },
      false,
      false,
      true,
    );

    if (!response.ok) {
      if (response.status === 401) {
        throw new AuthenticationError();
      } else if (response.status === 403) {
        throw new PermissionsError();
      } else {
        throw new HTTPError(response.status, response.data);
      }
    }

    return response;
  },
  resetPassword: async (email, isTournamentLogin) => {
    const result = await api(
      'post',
      'auth/request-password-reset',
      { email_address: email, is_tournament_login: isTournamentLogin },
      true,
      false,
      true,
    );

    return result.data;
  },
  newPassword: async (token, password, isTournamentLogin) => {
    const result = await api(
      'post',
      'auth/new-password',
      { token, password, is_tournament_login: isTournamentLogin },
      true,
      false,
      true,
    );
    return result.data;
  },
  validatePasswordResetToken: async (token) => {
    const result = await api(
      'get',
      `auth/validate-password-reset-token?token=${token}`,
      null,
      true,
      false,
      true,
    );
    return result;
  },
  tokenExchange: async (refreshToken) => {
    const response = await api(
      'post',
      'auth/token-exchange',
      { refresh_token: refreshToken },
      false,
      false,
      true,
    );

    return response;
  },
};
